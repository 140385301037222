<template>
  <b-container>
    <b-container v-if="!isLoggedIn" class="py-5">
      <div class="row mb-5">
        <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <div class="text-center mb-4">
            <h4>Sign up for a membership</h4>
          </div>
          <div v-if="selectedPlanID" class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center mb-2">
              <b-button
                block variant="outline-dark"
                :href="SPENV.ACCOUNTS_API_URL + '/oauth/auth-request?provider=google' + oauthparams">
                <span class="float-left mr-1"><font-awesome-icon :icon="['fab', 'google']" fixed-width /></span>
                <span class="mr-3">Sign Up with Google</span>
              </b-button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center mb-2">
              <b-button class="btn-linked-in" block :href="SPENV.ACCOUNTS_API_URL + '/oauth/auth-request?provider=linkedin' + oauthparams">
                <span class="float-left mr-1"><font-awesome-icon :icon="['fab', 'linkedin-in']" fixed-width /></span>
                <span class="mr-3">Sign Up with LinkedIn</span>
              </b-button>
            </div>
          </div>
          <div class="row text-center my-4">
            <div class="col">
              <div class="divider-line" />
              <div class="divider-or">
                or
              </div>
              <div class="divider-line" />
            </div>
          </div>
          <form>
            <fieldset>
              <div class="form-row">
                <!-- First Name -->
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <small>
                      <label for="first_name" :class="{'text-danger': errors.has('first_name')}">First name*
                        <span v-show="errors.has('first_name')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                    </small>
                    <input
                      id="first_name"
                      key="first_name"
                      v-model="form.first_name"
                      v-validate="'required'"
                      data-vv-validate-on="blur|change"
                      :class="{ 'is-invalid': errors.has('first_name') }"
                      class="form-control"
                      type="text"
                      name="first_name">
                  </div>
                </div> <!-- Last Name -->
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <small>
                      <label for="last_name" :class="{ 'text-danger': errors.has('last_name') }">Last name*
                        <span v-show="errors.has('last_name')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                    </small>
                    <input
                      id="last_name"
                      key="last_name"
                      v-model="form.last_name"
                      v-validate="'required'"
                      data-vv-validate-on="blur|change"
                      :class="{ 'is-invalid': errors.has('last_name') }"
                      class="form-control"
                      type="text"
                      name="last_name">
                  </div>
                </div>
              </div>

              <!-- Email -->
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <small>
                      <label for="email" :class="{ 'text-danger': errors.has('email') }">
                        Email* <span v-show="errors.has('email')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                    </small>
                    <input
                      id="email"
                      key="email"
                      v-model="email"
                      v-validate="'required|email|isExistingEmail'"
                      data-vv-validate-on="blur|change"
                      :class="{ 'is-invalid': errors.has('email') }"
                      class="form-control"
                      type="text" name="email">
                    <div class="text-danger">
                      <small>{{ errors.first('email') }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Password -->
              <div class="form-row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <small>
                      <label for="password" :class="{ 'text-danger': errors.has('password') }">Password* (at least 6 characters)
                        <span v-show="errors.has('password')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                    </small>
                    <input
                      id="password"
                      key="password"
                      ref="password"
                      v-model="form.password"
                      v-validate="'required|min:6'"
                      data-vv-validate-on="blur|change"
                      class="form-control"
                      :class="{ 'is-invalid': errors.has('password') }"
                      type="password"
                      name="password"
                      autocomplete="on">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <small>
                      <label for="confirm_password" :class="{ 'text-danger': errors.has('confirm_password') }">Confirm password*
                        <span v-show="errors.has('confirm_password')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                    </small>
                    <input
                      id="confirm_password"
                      key="confirm_password"
                      v-model="form.confirm_password"
                      v-validate="'required|confirmed:password'"
                      data-vv-validate-on="blur|input"
                      :class="{ 'is-invalid': errors.has('confirm_password') }" type="password"
                      class="form-control"
                      name="confirm_password"
                      value=""
                      autocomplete="on">
                  </div>
                </div>
              </div>

              <!-- Remember Me -->
              <div class="form-row">
                <div class="col-12">
                  <div class="checkbox form-group">
                    <label>
                      <input
                        id="remember_me"
                        v-model="form.remember_me"
                        type="checkbox"
                        name="remember_me">
                      <small class="pl-2">Remember me on this device</small>
                    </label>
                  </div>
                </div>
              </div>

              <!-- KCC Block -->
              <div v-if="show_kcc_block" class="alert alert-light text-center">
                <font-awesome-icon icon="exclamation-circle" />
                It looks like you are an employee of Kimberly-Clark.
                <p class="top-margin-sm">
                  You will be redirected to their signup page in {{ countdown }} seconds.
                </p>
              </div>
              <div v-if="show_edu_block" class="alert alert-light text-center">
                <p>
                  <font-awesome-icon icon="exclamation-circle" />
                  If you're a student using SolidProfessor in your class, you need to sign up through our Student Store.
                </p>
                <div class="mb-2">
                  <a role="button" class="btn btn-primary" :href="SPENV.STUDENT_STORE_URL">I'm a student. Take me to the Student Store.</a>
                </div>
                <div>
                  <button role="button" type="button" class="btn btn-outline-primary"
                          @click="dismissEduBlock()">
                    I'm not a student. Continue with sign up.
                  </button>
                </div>
              </div>
              <b-alert
                :show="show_error_block"
                variant="danger"
                dismissible
                @click="show_error_block = !show_error_block">
                {{ error_message }}
              </b-alert>
              <div v-if="!show_edu_block" class="form-row">
                <div id="recaptcha" class="g-recaptcha" />
                <VueRecaptcha
                  ref="recaptcha"
                  size="invisible"
                  badge="bottomleft"
                  :sitekey="SPENV.RECAPTCHA_SITEKEY"
                  :load-recaptcha-script="true"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired" />
                <div class="col-12 text-center mb-3">
                  <b-button
                    id="signup-submit"
                    variant="primary"
                    :disabled="!isFormValid"
                    @click="onSubmit">
                    Continue to payment
                  </b-button>
                </div>
              </div>
              <div class="form-row">
                <div class="offset-1 col-10 offset-md-2 col-md-8 text-center mb-3">
                  <small>
                    By creating this membership, you’re agreeing to SolidProfessor’s <a href="https://www.solidprofessor.com/terms-and-conditions/" target="_blank" class="btn-link">
                      Terms of Use</a> and <a href="https://www.solidprofessor.com/privacy/" target="_blank" class="btn-link">Privacy Policy</a>.
                  </small>
                </div>
              </div>
            </fieldset>
            <p class="text-center">
              <small>*Required Fields</small>
            </p>
          </form>
        </div>
      </div>
    </b-container>
    <!-- If you're already signed in, just show a disabled form -->
    <b-container v-else class="py-5">
      <div class="row mb-5">
        <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <div class="text-center mb-4">
            <h4>Your membership information</h4>
          </div>
          <form>
            <fieldset>
              <div class="form-row">
                <!-- First Name -->
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <small><label>First name</label></small>
                    <input
                      v-model="user.first_name"
                      class="form-control"
                      type="text"
                      disabled>
                  </div>
                </div> <!-- Last Name -->
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <small><label>Last name</label></small>
                    <input
                      v-model="user.last_name"
                      class="form-control"
                      type="text"
                      disabled>
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <small><label>Email address</label></small>
                    <input
                      v-model="user.email"
                      class="form-control"
                      type="text"
                      disabled>
                  </div>
                </div>
              </div>
              <div class="form-row mt-3">
                <div class="col-12 text-center mb-3">
                  <b-button :to="{ name: 'SignupPayment', params: { plan_id: selectedPlanID } }" variant="primary">
                    Continue to Payment
                  </b-button>
                </div>
              </div>
              <div class="form-row">
                <div class="offset-1 col-10 offset-md-2 col-md-8 text-center mb-3">
                  <small>By creating this membership, you’re agreeing to SolidProfessor’s <a href="https://www.solidprofessor.com/terms-and-conditions/" target="_blank" class="btn-link">
                    Terms of Use</a> and <a href="https://www.solidprofessor.com/privacy/" target="_blank" class="btn-link">Privacy Policy</a>.
                  </small>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </b-container>
  </b-container>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import VueRecaptcha from 'vue-recaptcha';
import analyticsClickHandler from '@/utils/mixins/Analytics.js';
import FullPageLoader from '@/utils/mixins/FullPageLoader.js';
import redirectMixin from '@/utils/mixins/Redirect';
import validationMixin from '@/utils/mixins/validation.js';

export default {
  name: 'AccountInfo',
  components: {VueRecaptcha},
  mixins: [analyticsClickHandler, FullPageLoader, redirectMixin, validationMixin],
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: '',
        remember_me: false,
      },
      form_disabled: false,
      show_kcc_block: false,
      show_edu_block: false,
      show_error_block: false,
      error_message: '',
      countdown: 3,
      activeLoader: false,
      oauthparams: '',
      query_string: '',
      newUser: true,
    };
  },
  computed: {
    selectedPlanID() {
      let value = null;
      if (this.$store.getters['plans/getSelectedPlan']) {
        value = this.$store.getters['plans/getSelectedPlan'].id;
      }
      return value;
    }
  },

  watch: {
    'error_message': function (newdata){
      console.log('error_message', newdata);
    },
    'email': function(newEmail) {
      if (newEmail && newEmail.indexOf('@kcc.com') !== -1) {
        this.doKccCountdown();
        this.show_kcc_block = true;
        this.form_disabled = true;
      } else if (!this.edu_block_dismissed && this.edu_regex.exec(newEmail)) {
        this.show_edu_block = true;
      }
    },
  },
  created() {
    if (!this.selectedPlanID) {
      this.$router.push({name: 'SignupSelectPlan'});
    }

    this.oauthparams = [
      '&action=signup&plan_id=',
      this.selectedPlanID,
      qs.stringify(this.$route.query),
      this.query_string,
    ].join('');

    this.edu_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.edu$/;
    this.query_string = qs.stringify(this.$route.query);
  },
  methods: {
    onSubmit: function() {
      this.$validator.validateAll().then(result => {
        // if form is valid, execute captcha which will trigger signup callback
        if (result) {
          if (process.env.NODE_ENV === 'production') {
            this.$refs.recaptcha.execute();
          } else {
            this.onCaptchaVerified('devpass');
          }
        }
      });
    },
    /**
     * Wrapper called to initate new User creation, and nav to the next page
     * There are two Promises because refactoring them would be significant:
     * The first promise should return a valid User object to be set in the Store
     * It doesnt.
     *
     * TODO: Refactor store and server side to do above
     * @param {string} recaptchaToken
     * @return {void}
     */
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.showFullPageLoading(true);
      new Promise((resolve, reject) => {
        this.createNewUser(this.processForm(recaptchaToken)).then(
          (response) => {
            this.newAccountAnalytics();
            new Promise((resolve, reject) => {
              this.loadUserInfo().then(
                (response) => {
                  this.showFullPageLoading(false);
                  this.$router.push({
                    name: 'SignupPayment',
                    params: {plan_id: this.selectedPlanID},
                  });
                }
              );
            });
          }
        );
      });
    },
    /**
     * Processes the submitted form into an associative array
     *
     * @param {string} recaptchaToken
     * @return {array} postBody -- associative array
     */
    processForm(recaptchaToken) {
      let postBody = {
        name: this.form.first_name,
        lastName: this.form.last_name,
        email: this.email,
        password: this.form.password,
        hearfrom: this.sourceform,
        rempass: this.form.remember_me,
        return_url: this.$store.getters['params/getReturnURL'],
        captcha_token: recaptchaToken,
      };
      if (this.$store.getters['params/getAffkey'] || this.$store.getters['params/getCampaignID'] || this.$store.getters['params/getGclid']) {
        postBody.lms_vars = {};
        if (this.$store.getters['params/getAffkey']) {
          postBody.lms_vars['affkey'] = this.$store.getters['params/getAffkey'];
        }
        if (this.$store.getters['params/getCampaignID']) {
          postBody.lms_vars['campaign_id'] = this.$store.getters[
            'params/getCampaignID'
          ];
        }
        if (this.$store.getters['params/getGclid']) {
          postBody.lms_vars['gclid'] = this.$store.getters['params/getGclid'];
        }
      }
      return postBody;
    },
    /**
     * Calls API to create new User
     *
     * @param {array} postbody -- associative array
     * @return {promise}
     */
    createNewUser(postBody) {
      return new Promise((resolve, reject) => {
        axios.post(this.SPENV.ACCOUNTS_API_URL + '/signup', postBody, { withCredentials: true }).then(
          (response) => {
            // TODO: This should be done in the store
            if (response.data && response.data.returnURL) {
              this.$store.commit('params/setReturnURL', response.data.return_url);
            }
            resolve();
          })
          .catch(error => {
            this.showFullPageLoading(false);
            this.$parent.$emit('login-alert', error.response.data);
            reject(error);
          });
      });
    },
    /**
     * Load User via Store dispatch
     *
     * @return {promise}
     */
    loadUserInfo() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('user/loadUserInfo').then(
          (response) => {
            resolve();
          }
        )
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Trigger analytics on account creation
     */
    newAccountAnalytics() {
      this.analyticsClickEvent('Account', 'New Account', 'New Account created');
      if (!this.edu_regex.exec(this.email)) {
        fbq('track', 'CompleteRegistration');
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    doKccCountdown() {
      if (this.countdown <= 0) {
        window.top.location = SPENV.APP_URL + '/iframes/optin.asp?c=sitelicense';
      } else {
        setTimeout(function() {
          this.countdown--;
          this.doKccCountdown();
        }, 1000);
      }
    },
    dismissEduBlock() {
      this.show_edu_block = false;
      this.edu_block_dismissed = true;
    },
    buildQueryString() {
      let qString = '';
      // adds return url if any EJL040318
      if (this.$store.getters['params/getReturnURL']) {
        let returnURL = this.$store.getters['params/getReturnURL'];
        qString += '&';
        qString += 'return_url=';
        qString += returnURL;
      }
      // adds google click id if any EJL040318
      if (this.$store.getters['params/getGclid']) {
        let gClid = this.$store.getters['params/getGclid'];
        qString += '&';
        qString += 'gclid=';
        qString += gClid;
      }
      // adds affkey if any EJL040318
      if (this.$store.getters['params/getAffkey']) {
        let affKey = this.$store.getters['params/getAffkey'];
        qString += '&';
        qString += 'affkey=';
        qString += affKey;
      }
      // adds campaign id if any EJL040318
      if (this.$store.getters['params/getCampaignID']) {
        let campaign = this.$store.getters['params/getCampaignID'];
        qString += '&';
        qString += 'campaign_id=';
        qString += campaign;
      }
      if (this.$store.getters['params/getSourceForm']) {
        let sform = this.$store.getters['params/getSourceForm'];
        qString += '&';
        qString += 'sourceform=';
        qString += sform;
      }
      // if any params need to be passed, prepends ? to qs
      this.query_string = qString;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
