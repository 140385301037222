import Vue from 'vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

Vue.use(VueCookies);

const laravelConnector = {
  methods: {
    connectToLaravelAPI(data) {
      /**
       * Agnostic and general purpose connector to access Laravel.
       * CORS safe!
       *
       * BUILD SUBCLASSES FROM HERE
       *
       * NOTE: Authorization is user specific Bearer token
       * @param {associative array} data -- Must include 'method' and 'url'
       * @return {Promise} -- with response data or error
       */
      return new Promise(function(resolve, reject) {
        axios({
          method: data['method'],
          url: process.env.SPENV.LARAVEL_API + data['url'],
          data: data['body'],
          params: data['params'],
          headers: {
            'Content-Type': 'application/json',
            'Authorization': VueCookies.get('laravel_bearer_token'),
          },
          validateStatus: function(status) {
            return status < 500; // Reject only if the status code is greater than or equal to 500
          },
          withCredentials: true,
        }).then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
        );
      });
    },
    laravelConnection(method, url, params=null, body=null) {
      /**
       * Wrapper for 'connectToLaravelAPI' to clean up parameter handling
       * @param {string} method -- connection method, 'GET', 'POST'
       * @param {string} url -- fully qualified URL
       * @param {Associative array} params -- to build URL params from
       * {'param_name': 'param_value'}
       */
      const axiosConnectionData = {
        method: method,
        url: url,
      };
      if (body) {
        axiosConnectionData['body'] = body;
      }
      if (params) {
        axiosConnectionData['params'] = params;
      }
      return this.connectToLaravelAPI(axiosConnectionData);
    },
  },
};

export default laravelConnector;
