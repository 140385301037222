import _ from 'lodash';
import laravelConnector from './laravelConnector';

const validationMixin = {
  mixins: [laravelConnector],
  created: function () {
    this.addCustomValidator();
  },
  computed: {
    selectedSchool: {
      get: function () {
        return this.$store.getters['studentStore/getSelectedSchool'];
      },
      set: function (school) {
        this.$store.commit('studentStore/setSelectedSchool', school);
      }
    },
    isFormValid () {
      return Object.keys(this.veeFields).every(field => {
        return this.veeFields[field] && this.veeFields[field].valid;
      });
    },
  },
  methods: {
    /**
     * Adds a custom validation to Vee Validate validation bag
     * IMPORTANT: the `validate` parameter is conditionally set by
     * the function called. The purpose is to prevent `newUser` from
     * using an existing email
     *
     * @return does not return
     */
    addCustomValidator () {
      // Check for existing email in DB
      this.$validator.extend('isExistingEmail', {
        getMessage: field => 'This email is invalid or is already in use. If you already have a SolidProfessor account using this email address, please log in.',
        validate: value => this.validateEmail()
      });
      // Check for valid school email
      this.$validator.extend('isRestrictedEmail', {
        getMessage: field => ' The instructor(s) at this school has restricted the email address domain that can be used to become a SolidProfessor member. If you aren\'t sure what email address domain to use, please contact your instructor.',
        validate: value => this.validateSchoolDomain()
      });
    },
    validateSchoolDomain () {
      if (_.has(this.selectedSchool, 'rowid') ) {
        return this.checkForValidDomain()
          .then(response => {
            let status = false;
            if (_.has(response, 'data.validEmail')) {
              status = response.data.validEmail;
            }
            return status;
          });
      }
      return true;
    },
    /**
     * Check if submitted email exists in our DB
     *
     * API will return:
     *    false: validation error OR email in use
     *    true: email is valid and not in use
     *
     * If the above fields are already determined, calls next step
     *
     * @return object of user/email data or empty object
     */
    validateEmail () {
      if (!this.newUser) return Promise.resolve(true);
      return new Promise((resolve, reject) => {
        this.laravelConnection('POST', 'email/validate', {email: this.email}).then(
          (response) => {
            if (response && response.data && response.status && response.status === 200 ) {
              resolve(true);
            }
            resolve(false);
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    checkForValidDomain () {
      let axiosConnectionData = {
        method: 'get',
        url: this.SPENV.APP_URL + '/api/v2/organization/' + this.selectedSchool.rowid + '/validate-domain?email=' + this.email,
        body: {}
      };
      return this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData);
    },
  },
};
export default validationMixin;
