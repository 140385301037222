import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

const FullPageLoader = {
  data() {
    return {
      fullPageLoader: null,
      loaderParams: {
        color: '#64A33D',
        zIndex: 99999
      }
    };
  },
  mounted: function() {},
  methods: {
    showFullPageLoading(state, params) {
      if (!params) params = this.loaderParams;
      if (state) {
        this.$root.fullPageLoader = this.$loading.show(params);
      } else {
        if (this.$root.fullPageLoader) this.$root.fullPageLoader.hide();
      }
    },
    showFullPageLoadingCancelable(state) {
      let params = this.loaderParams;
      params.canCancel = true;

      return this.showFullPageLoading(state, params);
    }
  },
  beforeDestroy: function() {
    if (this.fullPageLoader) this.showFullPageLoading(false);
  }
};

export default FullPageLoader;
